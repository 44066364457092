import React, { useContext } from "react";
import { UserContext } from "../../App";

export const withContext = (Component) => {
  const Wrapper = (props) => {
    const context = useContext(UserContext);

    return <Component context={context} {...props} />;
  };

  return Wrapper;
};