import React from "react";
import './AccessibilityBar.css';

class AccessibilityBar extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            h1_element: null,
            h1_font_size: 0.0,
            h2_font_size: 0.0,
            p_font_size: 0.0,
        };

        this.increaseFontSize = this.increaseFontSize.bind(this);

    }

    componentDidMount() {
        var h1Title = document.getElementsByClassName('course-title')[0];
        console.log(h1Title);
        var h1Style = window.getComputedStyle(h1Title, null).getPropertyValue('font-size');
        console.log(h1Style);
        var h1fontSize = parseFloat(h1Style);
        
        this.setState({
            h1_element: h1Title,
            h1_font_size: h1fontSize
        });
    }

    increaseFontSize() {
        var h1FontSize = this.state.h1_font_size;
        if(h1FontSize >= 22 && h1FontSize <= 50) {
            this.state.h1_element.style.fontSize = (h1FontSize + 1) + 'px';
            this.setState({
                h1_font_size: h1FontSize + 1
            });
            console.log(this.state.h1_font_size)
        }
    }

    render(){
        return(
            <div className="accessibility-bar">
                <span onClick={this.increaseFontSize} className="font-plus-btn">A+</span>
                <span className="font-minus-btn">A-</span>
            </div>
        )
    }
}

export default AccessibilityBar;