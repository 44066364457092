import React, { useState } from 'react';
import './App.css';
import './WidStyle.css';

import Routes from './Screens/Routes';

export const UserContext = React.createContext(null);

function App() {

  const [isOnline, setOnline] = useState(true);
    
  return (
    <div className="App">
      <UserContext.Provider value={{ isOnline: isOnline, setOnline: setOnline }}>
        <Routes/>
      </UserContext.Provider> 
    </div>
  );
}

export default App;
