import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';
import { Route, Redirect } from 'react-router-dom';
import getToken from '../utils/getToken';
import api from '../services/api';
import Loading from '../Components/UI/LoadingData/LoadingData';
import OfflinePage from '../Components/UI/OfflinePage/OfflinePage';
import useNetworkStatus from '../utils/useNetworkStatus';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const { isOnline, setOnline } = useContext(UserContext);

    useEffect(() => {
        const token = getToken();
        if(token){
            api.post('/checktoken', {}, { headers: { authorization: token } })
                .then(res => {
                    if(res.status === 200){
                        setAuthenticated(true);
                    } else {
                        localStorage.removeItem('wid_token');
                        setAuthenticated(false);
                    }
                })
                .catch(err => {
                    if(err.message === 'Network Error') {
                        setOnline(false);    
                    } else {
                        localStorage.removeItem('wid_token');
                        setAuthenticated(false);
                    }
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, []);

    useNetworkStatus();

    if (loading) {
        return <Loading />
    }

    if (!isOnline || !navigator.onLine ) {
        return <OfflinePage />
    }

    return (
        <Route {...rest} render={props => {
            if(authenticated){
                return <Component {...props} />;
            } else if(!authenticated && isOnline) {
                return <Redirect to={{ pathname: "/login", state: { from: props.location } }}/>;   
            } else if(!isOnline) {
                return <OfflinePage />    
            }
        }} />
    );
};
