import React from 'react';
import { withRouter } from 'react-router-dom';
import './Dashboard.css';
import staticConfig from '../../../constants';
import api from '../../../services/api';
import getToken from '../../../utils/getToken';
import getLoggedUser from '../../../services/getLoggedUser';
import Header from '../../../Components/UI/HeaderBarTop/HeaderBarTop';
import About from '../../../Components/Content/About/About';
import Modules from '../../../Components/Content/Modules/Modules';
import Test from '../../../Components/Test/TestsMonitoring/TestsMonitoring';
import FrequentQuestions from '../../../Components/Student/FrequentQuestions/FrequentQuestions';
import LoadingData from '../../../Components/UI/LoadingData/LoadingData';

class StudentDashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modulos: [],
            estudante: undefined,
            progresso: {},
            id_user: '',
            conteudo_selecionado: 'modulos',
            loading: true, // Adicionando estado de carregamento
            is_online: true
        }

        this.returnContent = this.returnContent.bind(this);
        this.switchContent = this.switchContent.bind(this);
    }

    componentDidMount() {
        if (getToken()) {
            api.get('/curso/' + staticConfig.course_id + '/modulo', { headers: { authorization: getToken() } }).then(res => {
                const modulos = res.data;
                modulos.sort((a, b) => { return a.ordem - b.ordem })
                this.setState({ modulos });
            });
        }
        var self = this
        getLoggedUser(function (userID) {
            if (userID !== null) {
                self.setState({ id_user: userID }, () => {
                    api.get('/progresso/' + self.state.id_user).then(res => {
                        self.setState({ progresso: res.data });
                    });
                    api.get('/estudante/' + self.state.id_user).then(res => {
                        self.setState(({ estudante: res.data, loading: false }), () => {
                            if (!self.state.estudante.pre_teste) {
                                self.props.history.push('/teste/pre-teste/introducao');
                            }
                        });
                    }).catch(err => {
                        // Em caso de erro na requisição, seta o estado de loading para false
                        self.setState({ loading: false });
                    });
                });
            }
        })
    }

    switchContent(content) {
        this.setState({ conteudo_selecionado: content });
    }

    returnContent() {
        if (this.state.loading) {
            return <LoadingData />; // Renderiza o indicador de carregamento
        }

        if (!this.state.estudante) {
            return <div>Estudante não encontrado</div>;
        }

        switch (this.state.conteudo_selecionado) {
            case 'modulos':
                if (this.state.modulos.length > 0) {
                    return (<Modules modulos={this.state.modulos} progresso={this.state.estudante.progresso} />)
                }
                return 'No modules';
            case 'sobre':
                return (<About />);
            case 'teste':
                return (<Test estudante={this.state.estudante} />);
            case 'perguntas':
                return (<FrequentQuestions />);
            default:
                return (<Modules modulos={this.state.modulos} />);
        }
    }

    render() {
        return (
            <div className='wrapper'>
                {this.state.estudante && <Header estudante={this.state.estudante} switch={this.switchContent} />}
                <div className='main-container-div'>
                    {this.returnContent()}
                </div> 
            </div>
        )
    }
}

export default withRouter(StudentDashboard);
