import React from 'react';
import './SectionContent.css';
import staticConfig from '../../../constants';
import api from '../../../services/api';
import { withRouter } from "react-router-dom";
import { withContext } from '../../Wrapper/WithContext';
import renderHTML from 'react-render-html';

class SectionContent extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            img_zoom: [],
            zoom: false,
            subsecao_atual: '',
            subs_index: 0,
            modulo: {},
            secao: {},
            conteudo: []
        };
        this.showLightBox = this.showLightBox.bind(this);
        this.removeLightBox = this.removeLightBox.bind(this);
        this.adaptContent = this.adaptContent.bind(this);
        this.changeSubSection = this.changeSubSection.bind(this);
        this.adaptSubsectionContent = this.adaptSubsectionContent.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        let modulo, secao; 
    
        api.get('/curso/' + staticConfig.course_id + '/modulo/' + this.props.id_modulo)
            .then(res => {
                modulo = res.data; 
                return api.get('/curso/' + staticConfig.course_id + '/modulo/' + this.props.id_modulo + '/secao/' + this.props.id_secao);
            })
            .then(res => {
                secao = res.data; 
                return api.get('/curso/' + staticConfig.course_id + '/modulo/' + this.props.id_modulo + '/secao/' + this.props.id_secao + '/conteudo');
            })

            .then(res => {
                const conteudo = res.data;
                if (this._isMounted) {
                    if (secao.subsecoes[this.state.subs_index]) {
                        this.setState({ modulo, secao, conteudo, subsecao_atual: secao.subsecoes[this.state.subs_index]._id });
                    } else {
                        this.setState({ modulo, secao, conteudo });
                    }
                }
            })
            .catch(error => {
                this.props.context.setOnline(false)
                console.error('Erro ao carregar dados:', error);
            });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showLightBox() {
        this.setState({ zoom: true })
    };

    removeLightBox() {
        this.setState({ zoom: false })
    }

    adaptContent(itemConteudo) {
        switch(itemConteudo.tipo) {
            case 'paragrafo':
                return (
                    <p key={itemConteudo._id} className="section-text mt2 fcFourth fsizeXSmall">{renderHTML(itemConteudo.conteudo)}</p>
                );
            case 'paragrafo-atencao':
                return (
                    <p key={itemConteudo._id} className="section-text mt2 fcPrimary fsizeXSmall">{renderHTML(itemConteudo.conteudo)}</p>
                );
            case 'titulo':
                return (
                    <p key={itemConteudo._id} className="section-title mt2 fcFourth fsizeXSmall"><b>{renderHTML(itemConteudo.conteudo)}</b></p>
                );
            case 'subtitulo-centralizado':
                return (
                    <p key={itemConteudo._id} className="section-centralized-subtitle mt2 fcFourth fsizeXSmall"><b>{renderHTML(itemConteudo.conteudo)}</b></p>
                );
            case 'imagem-zoom':
                return (
                    <div key={itemConteudo._id} className="section-imagem-zoom-wrapper mt2">
                        {this.state.zoom && <div id="lb-back" className="show" onClick={this.removeLightBox}>
                            <div className="section-imagem-in-zoom-wrapper">
                                <img className="section-imagem-in-zoom" src={itemConteudo.image_url} alt={itemConteudo.image_url}></img>
                            </div>
                        </div>}
                        <div onClick={this.showLightBox} className="section-image-overlay">
                            <p className="section-imagem-zoom-click">Clique para ampliar</p>
                        </div>
                        <img className="section-imagem-zoom" src={itemConteudo.image_url} alt={itemConteudo.image_url}></img>
                    </div>
                );
            default:
                return (
                    <p key={itemConteudo._id} className="section-text mt2 fcFourth fsizeXSmall">{renderHTML(itemConteudo.conteudo)}</p>
                );
        }
    }

    changeSubSection(sub_id){
        var sub_i;
        for (let i = 0; i < this.state.secao.subsecoes.length; i++) {
            if(this.state.secao.subsecoes[i]._id === sub_id) {
                sub_i = i;
                break;
            }
        }
        this.props.update_sub_index(sub_i);
        this.setState({ subs_index: sub_i, subsecao_atual: sub_id });
    }

    adaptSubsectionContent(tipo){
        switch(tipo) {
            case 'subsecao-vertical':
                return (
                    <div className="subsection-wrapper bcFiveth">
                        <div className="subsection-name-wrapper">
                        {this.state.secao.subsecoes.map((subsecao) => (
                            <span onClick={() => this.changeSubSection(subsecao._id)} key={subsecao._id} className={subsecao._id === this.state.subsecao_atual ? 'subsection-name bcPrimary' : 'subsection-name bcSecundary'}>{subsecao.nome}</span>
                        ))}    
                        </div>
                        <div className="subsection-content">{this.state.secao.subsecoes[this.state.subs_index] && renderHTML(this.state.secao.subsecoes[this.state.subs_index].conteudo)}</div>
                    </div>
                );
            case 'subsecao-horizontal':
                    return (
                        <div className="subsection-wrapper-horizontal bcFiveth">
                            <div className="subsection-name-wrapper-horizontal">
                            {this.state.secao.subsecoes.map((subsecao) => (
                                <span onClick={() => this.changeSubSection(subsecao._id)} key={subsecao._id} className={subsecao._id === this.state.subsecao_atual ? 'subsection-name bcPrimary' : 'subsection-name bcSecundary'}>{subsecao.nome}</span>
                            ))}    
                            </div>
                            <div className="subsection-content-horizontal">{this.state.secao.subsecoes[this.state.subs_index] && renderHTML(this.state.secao.subsecoes[this.state.subs_index].conteudo)}</div>
                        </div>
                    );
            default:
                return (
                    <div>
                        {tipo}
                    </div>
                );
        }    
    }

    render(){
        return (
            <div id="section-content" className={this.props.menu_colapsado ? "section-content-collapsed" : "section-content-not-collapsed"}>
                <h4 className="module-description mt1 fcSecundary fsizeXMedium">{this.state.modulo.nome} - {this.state.modulo.descricao} - {this.state.secao.titulo}</h4>
                {this.state.conteudo.map((itemConteudo) => (
                    this.adaptContent(itemConteudo)
                ))}
                {this.state.secao.subsecoes && this.adaptSubsectionContent(this.state.secao.tipo_subsecao)}
            </div>
        )
    }
}

export default withRouter(withContext(SectionContent));