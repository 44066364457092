import React from 'react';
import './OfflinePage.css';

const OfflinePage = () => {
    return (
        <div className="offline-container">
            <span className='offline-img'></span>
            <h1>Parece que você está offline!</h1>
            <p>Tente recarregar a página usando o seu navegador ou pressionando F5 no teclado <br/>quando a conexão com a internet for reestabelecida</p>
        </div>
    );
};

export default OfflinePage;