
import { useEffect, useContext, useCallback } from "react";
import { UserContext } from "../App";

const useNetworkStatus = () => {
    
  const { isOnline, setOnline } = useContext(UserContext);

  const updateNetworkStatus = useCallback(() => {
    setOnline(navigator.onLine);  
  }, [setOnline]);

  useEffect(() => {
    updateNetworkStatus();
  }, [updateNetworkStatus])

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
        window.removeEventListener("load", updateNetworkStatus);
        window.removeEventListener("online", updateNetworkStatus);
        window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [updateNetworkStatus]);

  return { isOnline };
};

export default useNetworkStatus;