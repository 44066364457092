import React from 'react';
import { withRouter } from 'react-router-dom';
import './ContentContainer.css';
import staticConfig from '../../constants';
import api from '../../services/api';
import insertAuth from '../../services/insertAuth';
import getLoggedUser from '../../services/getLoggedUser';
import MenuModuleProgressBar from '../../Components/Content/MenuModuleProgressBar/MenuModuleProgressBar';
import MenuSection from '../../Components/Content/MenuSection/MenuSection';
import SectionContent from '../../Components/Content/SectionContent/SectionContent';
import SectionSidebar from '../../Components/Content/SectionSidebar/SectionSidebar';
import { withContext } from '../../Components/Wrapper/WithContext';
import AccessibilityBar from '../../Components/UI/AccessibilityBar/AccessibilityBar';

class ScreenContentContainer extends React.Component{
    constructor(props){
        super(props);
    
        this.state = {
            id_user: '',
            curso: {},
            progresso_user: {},
            modulos: [],
            menu_colapsado: false,
            gettingInfo: false,
            modulo_index: 0,
            secao_index: 0,
            subsection_index: 0,
            modulo_id: this.props.match.params.modulo_id
        };

        this.loadModule = this.loadModule.bind(this);
        this.updateStateParams = this.updateStateParams.bind(this);
        this.nextClass = this.nextClass.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        this.updateSubSectionIndex = this.updateSubSectionIndex.bind(this);
        this.existProgress = this.existProgress.bind(this);
        this.checkProgress = this.checkProgress.bind(this)
        this.handleMenuScroll = this.handleMenuScroll.bind(this);
        this.collapseMenu = this.collapseMenu.bind(this);

    }

    componentDidMount() {
        // Obtém todos os módulos do curso e depois chama loadModule() pra carregar o indice do
        // modulo atual baseado na variavel modulo_id do state
        api.get('/curso/'+staticConfig.course_id+'/modulo', insertAuth()).then(res => {
            const modulos_response = res.data;
            modulos_response.sort((a, b) => {return a.ordem - b.ordem})
            this.setState(() => ({
                modulos: modulos_response,
                gettingInfo: true
            }), () => {
                // Obtém informações sobre o curso
                api.get('/curso/'+staticConfig.course_id, insertAuth()).then(res => {
                    const cursoRes = res.data;
                    this.setState(({ curso: cursoRes, gettingInfo: false }), () => {
                        // Obtém o id e o progrresso do usuário
                        var self = this
                        getLoggedUser(function(userID){
                            if(userID !== null){
                                self.setState({ id_user: userID, gettingInfo: true }, () => {
                                    api.get('/progresso/'+self.state.id_user).then(res => {
                                        if(res.data){
                                            self.setState(({ progresso_user: res.data,
                                                             gettingInfo: false }), () => {
                                                self.loadModule();
                                            });
                                        } else {
                                            self.setState({gettingInfo: false })
                                        }
                                    }).catch(err => {
                                        if(err.message === 'Network Error') {
                                            this.props.context.setOnline(false)    
                                        }
                                    });
                                });
                            }
                        })
                    });
                }).catch(err => {
                    if(err.message === 'Network Error') {
                        this.props.context.setOnline(false)    
                    }
                });
            });
        }).catch(err => {
            if(err.message === 'Network Error') {
                this.props.context.setOnline(false)    
            }
        });
        this.setState({
            mounted: true
        })
    }

    // Função para carregar um determinado módulo baseado na variável modulo_id do state
    loadModule(){
        var module_index;
        for (let i = 0; i < this.state.modulos.length; i++) {
            if(this.state.modulos[i]._id === this.state.modulo_id) {
                module_index = i;
                break;
            }
        }
        if(module_index > 0) { // Só deixa passar para os próximos módulos quando concluir o primeiro
            if(this.state.progresso_user.progresso_modulos){
                if(this.state.progresso_user.progresso_modulos.length > 0){
                    var progresso_modulos = this.state.progresso_user.progresso_modulos;
                    for (let index = 0; index < progresso_modulos.length; index++) {
                        if(progresso_modulos[index].id_modulo === this.state.modulos[0]._id){
                            if(progresso_modulos[index].progresso !== 100){
                                module_index = 0;
                            }    
                        }
                    }
                } else {
                    module_index = 0;   
                }
            } else {
                module_index = 0;
            }
        } 
        this.setState({
            modulo_index: module_index,
            secao_index: 0,
        })
    }

    // Alterar o índice de uma subseção
    updateSubSectionIndex(index) {
        this.setState({ subsection_index: index });
    }

    // Seta no state os índices do modulo secão e subsecao conforme os ids do módulo e da seção
    // passados como parâmetros
    updateStateParams(module_id, section_id) {
        var module_index;
        var section_index;
        for (let i = 0; i < this.state.modulos.length; i++) {
            if(this.state.modulos[i]._id === module_id) {
                module_index = i;
                break;
            }
        }
        var secoes = this.state.modulos[module_index].secoes;
        for (let j = 0; j < secoes.length; j++) {
            if(secoes[j] === section_id) {
                section_index = j;
                break;
            }
        }
        this.setState(() => ({
            modulo_index: module_index,
            secao_index: section_index,
            subsection_index: 0
        }))
    }

    // Atualiza o progresso para o id do modulo e secao passados como parametros ok 
    async updateProgress(module_id, section_id){
        var module_index;
        var section_index;
        var m_progress_index;

        // Encontra o indice do modulo no progresso
        if(this.state.progresso_user.progresso_modulos) {
            var progresso_modulos = this.state.progresso_user.progresso_modulos;
            for (let index = 0; index < progresso_modulos.length; index++) {
                if(progresso_modulos[index].id_modulo === module_id){
                    m_progress_index = index;
                }
            }
        } else {
            m_progress_index = 0;   
        }
        
        // Encontra o indice do modulo
        for (let i = 0; i < this.state.modulos.length; i++) {
            if(this.state.modulos[i]._id === module_id) {
                module_index = i;
                break;
            }
        }

        // Encontra o indice da secao
        var secoes = this.state.modulos[module_index].secoes;
        for (let j = 0; j < secoes.length; j++) {
            if(secoes[j] === section_id) {
                section_index = j;
                break;
            }
        }

        // Calculca quantos % aquela seção representa dentro daquele módulo
        var progresso = ((section_index + 1) / secoes.length) * 100;

        let progresso_updated = JSON.parse(JSON.stringify(this.state.progresso_user));
        let total_secoes_atual = 0;
        if(!progresso_updated.progresso_modulos[m_progress_index].progresso_secoes.includes(section_id)){
            progresso_updated.progresso_modulos[m_progress_index].progresso_secoes.push(section_id);
            for (let index = 0; index < progresso_updated.progresso_modulos.length; index++) {
                total_secoes_atual += progresso_updated.progresso_modulos[index].progresso_secoes.length;
            }
        }

        if(progresso_updated.progresso_modulos[m_progress_index].progresso) {
            if((progresso_updated.progresso_modulos[m_progress_index].progresso > progresso) &&
                progresso_updated.progresso_modulos[m_progress_index].progresso < 100){
                var section_per = ((1 / secoes.length) * 100);
                progresso_updated.progresso_modulos[m_progress_index].progresso += section_per;    
            } else {
                progresso_updated.progresso_modulos[m_progress_index].progresso = progresso;    
            }
        } else {
            progresso_updated.progresso_modulos[m_progress_index].progresso = progresso;    
        }
        
        let progresso_geral = (total_secoes_atual / this.state.curso.total_secoes) * 100;
        progresso_updated.progresso = progresso_geral;

        // Atualiza o progresso no backend e no state
        var data = {
            id_usuario: this.state.id_user,
            id_curso: staticConfig.course_id,
            id_modulo: module_id,
            id_secao: section_id,
            progresso: progresso_geral,
            progresso_modulo: progresso
        }
        await api.put('/progresso', data).then(res => {
            if(res){   
                this.setState({
                    progresso_user: progresso_updated
                });
            }    
        }).catch(err => {
            if(err.message === 'Network Error') {
                this.props.context.setOnline(false)    
            }
            throw err;
        });       
    }

    // Checa se existe progresso para o dado módulo e seção passados como parametros
    async existProgress(module_id, section_id){
        if(this.state.progresso_user.progresso_modulos){ // Usuário ja inicializou o progresso
            const progresso_modulos = this.state.progresso_user.progresso_modulos;
            for (let i = 0; i < progresso_modulos.length; i++) {
                if(progresso_modulos[i].id_modulo === module_id) {
                    var progresso_secoes = progresso_modulos[i].progresso_secoes;
                    if(progresso_secoes.includes(section_id)) {
                        return true;
                    }
                } 
            }  
        } else { // Progresso sendo criado para o usuário pela primeira vez
            var new_progress = {
                id_usuario: this.state.id_user,
                id_curso: staticConfig.course_id,
                progresso: 0.0,
                progresso_modulos: [
                    {
                        id_modulo: module_id,
                        progresso: 0,
                        progresso_secoes: [ ]
                    }
                ]
            }
            this.setState(({ progresso_user: new_progress}), async () => {
                await api.post('/progresso', new_progress).then(async () => {
                    this.setState(({ gettingInfo: false }), () => {
                        this.performNextClass();
                    });
                    return false;
                }).catch(err => {
                    if(err.message === 'Network Error') {
                        this.props.context.setOnline(false)    
                    }
                });
            });
        }
    }

    // Passa para próxima aula atualizando o progresso e o índice
    async nextClass() {
        this.setState(({ gettingInfo: true }), async () => {
            // Atualizar progresso para o módulo e secão anterior
            var mi = this.state.modulos[this.state.modulo_index]._id;
            var si = this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index];
            await this.existProgress(mi, si).then(existProgress => {
                if(existProgress) {
                    this.setState(({ gettingInfo: false }), () => {
                        this.performNextClass();
                    });
                } else {
                    const progresso_modulos = this.state.progresso_user.progresso_modulos.map((modulo) => modulo.id_modulo);
                    if(progresso_modulos.includes(mi)) { // Progresso do módulo já foi inicializado
                        this.updateProgress(mi, si).then(() => {
                            this.setState(({ gettingInfo: false }), () => {
                                this.performNextClass();
                            });
                        });
                    } else { // Progesso do módulo deve ser incializado antes
                        var new_module = {
                            progresso: 0,                
                            progresso_secoes: [ ],
                            id_modulo: mi
                        }
                        var new_module_progress = {
                            id_usuario: this.state.id_user,
                            id_curso: staticConfig.course_id,
                            progresso_modulo: new_module 
                        }
                        this.setState((state) => {
                            let progresso_updated = JSON.parse(JSON.stringify(state.progresso_user));
                            progresso_updated.progresso_modulos.push(new_module);
                            return {
                                gettingInfo: true,
                                progresso_user: progresso_updated
                            }
                        }, async () => {
                            await api.post('/progresso/modulo', new_module_progress).then(async () => {
                                await this.updateProgress(mi, si).then(() => {
                                    this.setState(({ gettingInfo: false }), () => {
                                        this.performNextClass();
                                    });
                                });
                            }).catch(err => {
                                if(err.message === 'Network Error') {
                                    this.props.context.setOnline(false)    
                                }
                            }); 
                        }); 
                    }
                }
            });
        });
    }

    performNextClass(){
        // Lógica pra mudar de aula
        if(this.state.modulo_index < this.state.modulos.length){
            if(this.state.secao_index < (this.state.modulos[this.state.modulo_index].secoes.length - 1)) {
                this.setState((state) => ({
                    secao_index: state.secao_index + 1,
                    subsection_index: 0
                }));
            } else { // Terminou um módulo
                var id_finalizado = this.state.modulos[this.state.modulo_index]._id;
                if(this.state.modulo_index + 1 < this.state.modulos.length){
                    this.setState((state) => ({
                        secao_index: 0,
                        subsection_index: 0,
                        modulo_index: state.modulo_index + 1
                    }), () => {
                        if(this.state.progresso_user.progresso === 100){
                            this.props.history.push('/conteudo/modulo/finalizado/'+id_finalizado+'/curso_finalizado');    
                        } else {
                            var id_proximo = this.state.modulos[this.state.modulo_index]._id;
                            this.props.history.push('/conteudo/modulo/finalizado/'+id_finalizado+'/'+id_proximo);
                        }
                    });
                } else { // Terminou o último módulo
                    if(this.state.progresso_user.progresso === 100) {
                        this.props.history.push('/conteudo/modulo/finalizado/'+id_finalizado+'/curso_finalizado');
                    } else {
                        let progresso_modulos = this.state.progresso_user.progresso_modulos;
                        let id_proximo = this.state.modulos[0]._id;
                        for (let index = 0; index < progresso_modulos.length; index++) {
                            if(progresso_modulos[index].progresso < 100){
                                id_proximo = progresso_modulos[index].id_modulo;
                                break;
                            }
                        }
                        this.props.history.push('/conteudo/modulo/finalizado/'+id_finalizado+'/'+id_proximo);    
                    }
                    
                }
               
            }
        }
    }

    checkProgress(modulo) {
        if(this.state.progresso_user.progresso_modulos){
            return (
                <div className="menu-module-classes mt2 ml4">
                    {modulo.secoes.map((secao) => (
                        <MenuSection key={secao} 
                                        id_secao={secao} 
                                        secao_atual={this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index]} 
                                        id_modulo={modulo._id}
                                        progresso_modulos={this.state.progresso_user.progresso_modulos}
                                        updateStateParams={this.updateStateParams} />
                    ))}                  
                </div>
            )
        } else {
            return (
                <div className="menu-module-classes mt2 ml4">
                    {modulo.secoes.map((secao) => (
                        <MenuSection key={secao} 
                                        id_secao={secao} 
                                        secao_atual={this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index]} 
                                        id_modulo={modulo._id}
                                        progresso_modulos={[]}
                                        updateStateParams={this.updateStateParams} />
                    ))}                  
                </div>
            )    
        }
    }

    loadMenuProgressBar(modulo){
        if(this.state.progresso_user.progresso_modulos){
            return (<MenuModuleProgressBar key={modulo._id} 
                        id_modulo={modulo._id} 
                        progresso_modulos={this.state.progresso_user.progresso_modulos} /> ) 
        }
        else {
            return (<MenuModuleProgressBar key={modulo._id} 
                        id_modulo={modulo._id} 
                        progresso_modulos={undefined} /> ) 
        }
    }

    handleMenuScroll(e){
        var menuDiv = e.target;
        if(menuDiv.scrollTop > 400){
            menuDiv.classList.add('menu-course-modules-scroll');
        } else {
            menuDiv.classList.remove('menu-course-modules-scroll');
        }
    }

    collapseMenu(){
        if(this.state.menu_colapsado){ // Descolapsar
            var menuContainer = document.getElementById("menu-course-container");
            menuContainer.classList.remove('menu-course-container-collapsed');
            menuContainer.classList.add('menu-course-container-not-collapsed');

            // var menuModules = document.getElementById("menu-course-modules");
            // menuModules.classList.remove('not-display');

            var menuHeader = document.getElementById("menu-course-header-firts");
            menuHeader.classList.remove('not-display');

            this.setState({ menu_colapsado: false });
        } else { // Colapsar
            var menuContainer = document.getElementById("menu-course-container");
            menuContainer.classList.remove('menu-course-container-not-collapsed');
            menuContainer.classList.add('menu-course-container-collapsed');

            // var menuModules = document.getElementById("menu-course-modules");
            // menuModules.classList.add('not-display');
            
            var menuHeader = document.getElementById("menu-course-header-firts");
            menuHeader.classList.add('not-display');

            this.setState({ menu_colapsado: true });
        }
        
    }

    render(){
        return(
            <div className='wrapper directionRow'>
                <div id="menu-course-container" className="menu-course-container-not-collapsed">
                    <div className="menu-course-header">
                        <div id="menu-course-header-firts">
                            <a href="/" title="Retornar ao Painel do Estudante"><span className="icon-back"></span></a>
                            <h1 className="menu-course-header-title fsizeXMedium">MÓDULOS DE ENSINO</h1>
                        </div>
                        <span onClick={this.collapseMenu} id="icon-collapse-menu" className={this.state.menu_colapsado ? 'icon-collapse-menu-collapsed' : 'icon-collapse-menu-not-collapsed'}></span>
                    </div>
                    <div className={this.state.menu_colapsado ? "not-display" : "menu-course-modules"} onScroll={this.handleMenuScroll}>
                        {(this.state.modulo_index + 1) && this.state.modulos.map(modulo => (
                            <div key={modulo._id} className="menu-module-container mt4">
                                <div className="menu-module-header">
                                    <h1 className="menu-module-header-title fsizeXMedium fcSecundary">{modulo.nome} - {modulo.descricao}</h1>
                                </div>
                                { this.loadMenuProgressBar(modulo) }                                           
                                { this.checkProgress(modulo) }
                            </div>
                        ))}
                    </div>
                </div>
                {this.state.modulos[this.state.modulo_index] && <div className="section-content-wrapper directionRow">
                    <div className="directionColumn">
                        <h1 className="course-title fcSecundary fsizeLarge">{this.state.curso.descricao}</h1>
                        <SectionContent key={this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index]} 
                                        id_modulo={this.state.modulos[this.state.modulo_index]._id} 
                                        id_secao={this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index]}
                                        update_sub_index={this.updateSubSectionIndex}
                                        menu_colapsado={this.state.menu_colapsado} />
                        <AccessibilityBar />
                    </div>
                    <div id="section-sidebar" className="directionColum">
                        <SectionSidebar key={this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index]} 
                                        id_modulo={this.state.modulos[this.state.modulo_index]._id} 
                                        id_secao={this.state.modulos[this.state.modulo_index].secoes[this.state.secao_index]}
                                        sub_index={this.state.subsection_index} />
                    </div>
                    {!this.state.gettingInfo ? <a onClick={this.nextClass} className="link-button-next-class fsizeSmall">PRÓXIMA AULA</a>: <span className="loading-next-class">Carregando...</span>}
                </div>}
            </div> 
        )
    }
}

export default withRouter(withContext(ScreenContentContainer));